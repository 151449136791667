import RequestBuilder from "./RequestBuilder";
import {METHOD_CREATE, METHOD_DELETE, METHOD_READ, METHOD_UPDATE} from "../data/constants";

const create = (url, data = null) => {
  return new RequestBuilder().setMethod(METHOD_CREATE).setUrl(url).setData(data);
};

const read = (url, params = null) => {
  return new RequestBuilder().setMethod(METHOD_READ).setUrl(url).setParams(params);
};

const update = (url, data = null) => {
  return new RequestBuilder().setMethod(METHOD_UPDATE).setUrl(url).setData(data);
};

const remove = (url, params = null) => {
  return new RequestBuilder().setMethod(METHOD_DELETE).setUrl(url).setParams(params);
};

export {create, read, update, remove};