const TOKEN_FIELD = 'token';
const TOKEN_IS_REFRESHING_FIELD = 'token_is_refreshing';

const getToken = () => {
  const json = localStorage.getItem(TOKEN_FIELD);

  if (null === json) {
    return null;
  }

  return JSON.parse(json);
};

/**
 * @param {Object} token
 */
const setToken = (token) => {
  localStorage.setItem(TOKEN_FIELD, JSON.stringify(token));
};

const removeToken = () => {
  localStorage.removeItem(TOKEN_FIELD);
};

const createTokenIsRefreshing = () => {
  localStorage.setItem(TOKEN_IS_REFRESHING_FIELD, Date.now().toString());
};

const getTokenIsRefreshing = () => {
  const time = localStorage.getItem(TOKEN_IS_REFRESHING_FIELD);

  if (null === time) {
    return null;
  }

  return parseInt(time);
};

const removeTokenIsRefreshing = () => {
  localStorage.removeItem(TOKEN_IS_REFRESHING_FIELD);
};

export {
  getToken,
  setToken,
  removeToken,
  createTokenIsRefreshing,
  getTokenIsRefreshing,
  removeTokenIsRefreshing,
};