import {client} from "./client";
import {refreshToken} from "./refresh-token";
import {scope} from "./scope";
import {signIn} from "./sign-in";
import {signOut} from "./sign-out";

const oauth = {
  client,
  refreshToken,
  scope,
  signIn,
  signOut,
};

export {oauth};