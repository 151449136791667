import {token} from "./token";

const isAuthenticated = () => {
  return !token.isExpired() || token.canRefresh();
};

const getAuthHeaders = () => {
  return {Authorization: `${token.type} ${token.access}`};
};

export {isAuthenticated, getAuthHeaders};