<template>
  <ModalDialog title="Забронировать аудиторию"  id="reserveModalMobile" class="reserve-modal">
    <div class="description mb-5">
      аудитория {{ classroomName }}, {{studyDay}}, {{ bell }}
    </div>
    <ReserveForm
        :bell="bell"
        :studyDay="studyDay"
        :classroomName="classroomName"
        :modal="modal"
        idModal="reserveModal"
    />
  </ModalDialog>
</template>

<script setup>
import ModalDialog from "@/features/modal/components/ModalDialog.vue";
import {useRoute} from "vue-router";
import {defineProps} from "vue";
import ReserveForm from "@/features/form/components/ReserveForm.vue";

defineProps({
  bell: {
    type: String,
    required: true,
  },
  studyDay: {
    type: String,
    required: true,
  },
  modal:{
    type: Object
  }
});


const route = useRoute();
const {classroomName} = route.params;

</script>

<style lang="scss" scoped>
.modal.reserve-modal {
  .modal-dialog {
    .modal-content {
      .modal-body {
        .description {
          text-align: center;
          font-size: .9rem;
          text-transform: lowercase;
          color: #444;
          font-family: "Acrom", sans-serif;
        }
      }
    }
  }
}
</style>