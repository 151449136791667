import {OAUTH_CLIENT_ID, OAUTH_CLIENT_SECRET} from "../../data/constants";
import {request} from "../../request/request";

const signIn = {
  create: (data) => {
    const oauthCredentials = {
      grant_type: 'password',
      client_id: OAUTH_CLIENT_ID,
      client_secret: OAUTH_CLIENT_SECRET,
      scope: '',
    };

    return request.create('/oauth/sign-in', Object.assign({}, oauthCredentials, data));
  },
};

export {signIn};