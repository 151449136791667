import {create, read, remove, update} from "./crud";
import {httpClient} from "../client/http-client";

const request = {
  create: (url, data = {}, auth = false) => {
    const builder = create(url, data);

    if (auth) {
      builder.auth();
    }

    return httpClient().call(builder.build());
  },
  read: (url, params = null, auth = false) => {
    const builder = read(url, params);

    if (auth) {
      builder.auth();
    }

    return httpClient().call(builder.build());
  },
  update: (url, data = {}, auth = false) => {
    const builder = update(url, data);

    if (auth) {
      builder.auth();
    }

    return httpClient().call(builder.build());
  },
  remove: (url, params = null, auth = false) => {
    const builder = remove(url, params);

    if (auth) {
      builder.auth();
    }

    return httpClient().call(builder.build());
  },
};

export {request};