export default class Queue {
  _items = [];

  push(request) {
    return new Promise((resolve, reject) => {
      const handler = async (client) => {
        try {
          resolve(await client.run(request));
        } catch (error) {
          reject(error);
        }
      };

      this._items.push(handler);
    });
  }

  buildItems(client) {
    return this._items.map((handler) => Promise.resolve(handler(client)));
  }

  reset() {
    this._items = [];
  }
}