import "bootstrap/dist/css/bootstrap.min.css"
import {createApp} from 'vue';
import App from './App.vue';
import {router} from "@/libs/router/builder";
import Vuelidate from '@vuelidate/validators';
import {SmartCaptchaPlugin} from 'vue3-smart-captcha';

createApp(App)
  .use(router)
  .use(Vuelidate)
  .use(SmartCaptchaPlugin)
  .mount('#app');

import "bootstrap"
