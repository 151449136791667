<template>
  <ul class="filter-items tabs">
    <li v-for="(studyDay, index) in studyDaysShort"
        :key="studyDay"
        class="tab"
        @click="handleFilterClick(index)"
    >
      <ButtonSimple
          :text="studyDay.name"
          :active="studyDay.id === getSelectDay()"
      />
    </li>
  </ul>
  <Carousel
      v-bind="config"
      ref="myCarousel"
      @slide-end="handleSlideEnd"
  >
    <Slide v-for="studyDay in studyDays" :key="studyDay">
      <table class="schedule-table mobile">
        <thead>
        <tr>
          <th class="schedule-table__time-column">
            <ScheduleHeaderCell text="Время"/>
          </th>
          <th class="schedule-table__day-column" >
            <ScheduleHeaderCell :text="studyDay.name"/>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="bell in bells" :key="bell.id">
          <td>
            <ScheduleHeaderCell :text="bell.begin"/>
          </td>
          <td class="schedule-table__cell"
              :class="getCellCssClass(schedule[computeIndex(studyDay.id, bell.begin)])"
          >
            <template v-if="schedule[computeIndex(studyDay.id, bell.begin)]">
              <template v-if="Array.isArray(schedule[computeIndex(studyDay.id, bell.begin)])">
                <template v-for="scheduleItem in schedule[computeIndex(studyDay.id, bell.begin)]" :key="scheduleItem.id">
                  <ScheduleCell
                      :lesson="scheduleItem.lesson"
                      :classroom="type !== SCHEDULE_TYPE_CLASSROOM ? scheduleItem.classroom : null"
                      :group="type === SCHEDULE_TYPE_CLASS ? getSubgroup(scheduleItem.schoolClasses) : null"
                      :teacher="type !== SCHEDULE_TYPE_TEACHER ? scheduleItem.teacher : null"
                      :schoolClasses="type !== SCHEDULE_TYPE_CLASS ? scheduleItem.schoolClasses : null"/>
                </template>
              </template>
              <template v-else>
                <ScheduleCell
                    :lesson="schedule[computeIndex(studyDay.id, bell.begin)].lesson"
                    :classroom="type !== SCHEDULE_TYPE_CLASSROOM ? schedule[computeIndex(studyDay.id, bell.begin)].classroom: null"
                    :group="type === SCHEDULE_TYPE_CLASS ? getSubgroup(schedule[computeIndex(studyDay.id, bell.begin)].schoolClasses) : null"
                    :teacher="type !== SCHEDULE_TYPE_TEACHER ? schedule[computeIndex(studyDay.id, bell.begin)].teacher : null"
                    :schoolClasses="type !== SCHEDULE_TYPE_CLASS ? schedule[computeIndex(studyDay.id, bell.begin)].schoolClasses : null"/>
              </template>
            </template>
            <template v-else>
              <template v-if="canReserve">
                <div class="schedule-cell-reserve">
                  <LinkItem
                      to="#"
                      text="Забронировать"
                      @click="handleClick(studyDay.name, bell.begin)"
                  />
                </div>
              </template>
            </template>
          </td>
        </tr>
        </tbody>
      </table>
    </Slide>
  </Carousel>
  <ReserveModalMobile :studyDay="studyDay" :bell="bell" :modal="modal"/>
</template>


<script setup>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'
import {defineProps, onMounted, ref} from "vue";
import {Modal} from "bootstrap";
import ScheduleHeaderCell from "@/features/schedule/components/ScheduleHeaderCell.vue";
import LinkItem from "@/components/LinkItem.vue";
import ScheduleCell from "@/features/schedule/components/ScheduleCell.vue";
import {useRoute} from "vue-router";
import {SCHEDULE_TYPE_TEACHER, SCHEDULE_TYPE_CLASS, SCHEDULE_TYPE_CLASSROOM} from "@/data/constants";
import {studyDaysShort} from "@/data/days";
import ButtonSimple from "@/components/ButtonSimple.vue";
import ReserveModalMobile from "@/components/ReserveModalMobile.vue";


const route = useRoute();
const {schoolClassName} = route.params;
const myCarousel = ref(null);
const currentSlideIndex = ref();
const modal = ref();
const studyDay = ref();
const bell = ref();
const currentDayIndex = ref();

defineProps({
  studyDays: {
    type: Array,
    default: () => [],
  },
  bells: {
    type: Array,
    default: () => [],
  },
  isMobile: {
    type: Boolean,
    default: true,
  },
  schedule: {
    type: Array,
    default: () => [],
  },
  type: {
    type: Number,
    required: true,
  },
  canReserve: Boolean
});

const config = {
  wrapAround: true,
  modelValue: currentDayIndex
};
const handleSlideEnd = (data) => {
  currentSlideIndex.value = data.currentSlideIndex;
}

const handleClick = (studyDayCell, bellCell) => {
  studyDay.value = studyDayCell;
  bell.value = bellCell;
  modal.value = new Modal(document.getElementById('reserveModalMobile'), {});
  modal.value.show();
}
const handleFilterClick = (index) => {
  myCarousel.value.slideTo(index);
}
const getCellCssClass = (items) => {
  if (!items || !Array.isArray(items)) return 'empty';
  if (items.length < 2) return '';
  return 'schedule-table__cell-list';
}

const getSubgroup = (groups) => {
  if (!schoolClassName) return null;
  let subGroups = groups.filter((group) => group.name = schoolClassName);
  return (undefined !== subGroups[0]) ? subGroups[0] : null;
}
const computeIndex = (weekday, time) => {
  return weekday + '-' + time;
};

const getSelectDay = () => {
  let day = currentSlideIndex.value + 1;
  if (!day) {
    let d = new Date();
    return [1, 1, 2, 3, 4, 5, 6][d.getDay()];
  }
  return day;
}
onMounted(() => {
  currentDayIndex.value = getSelectDay() - 1;
});



</script>

<style lang="scss" scoped>
.carousel{
  .carousel__slide{
    align-items: flex-start;
    text-align: left;
  }
}
.schedule-table {
  width: 100%;
  .schedule-table__time-column {
    width: 10%;
  }
  .schedule-table__day-column {
    width: 15%;
  }
  thead {
    tr {
      background-color: #E8EDF3;
    }

    th {
      border: 1px solid #FFFFFF;
    }
  }
  tbody {
    td {
      border: 1px solid #E8EDF3;
      &.schedule-table__cell {
        vertical-align: top;
      }
    }
  }
  &.mobile {
    .schedule-table__time-column {
      width: 20%;
    }
    .schedule-table__day-column {
      width: 80%;
    }
    .empty{
      transition: all .1s linear;
      .schedule-cell-reserve{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        gap: 10px;
        padding: 10px 10px 20px;
        .link-item{
          font-size: .75rem;
          padding:10px 0;
          width: 100%;
        }
      }
    }
    .schedule-cell{
      .schedule-cell__content{
        align-items: flex-start;
      }
    }
  }
}
.tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  padding: 0
}
.filter-items{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
  overflow: hidden;
  height: auto
}

</style>