import {METHOD_CREATE, METHOD_DELETE, METHOD_READ, METHOD_UPDATE} from "../data/constants";
import {cloneDeep} from "lodash";
import {InvalidMethodError, InvalidTypeError} from "../exceptions/request-errors";

export default class RequestBuilder {
  isAuthenticated = false;

  url = '/';

  method = 'get';

  params = null;

  headers = {};

  data = null;

  auth() {
    this.isAuthenticated = true;
    return this;
  }

  setUrl(value) {
    if (typeof value !== 'string') {
      throw new InvalidTypeError('URL must be a string.');
    }

    this.url = value;
    return this;
  }

  setMethod(value) {
    if (![METHOD_CREATE, METHOD_READ, METHOD_UPDATE, METHOD_DELETE].includes(value)) {
      throw new InvalidMethodError('Unexpected request method.');
    }

    this.method = value;
    return this;
  }

  setParams(params) {
    if (null !== params && !(params instanceof URLSearchParams)) {
      throw new InvalidTypeError('Params must be NULL or an URLSearchParams object.');
    }

    if (null === params) {
      this.params = null;
    } else {
      this.params = new URLSearchParams();

      for (const [key, value] of params.entries()) {
        this.params.set(key, value);
      }
    }

    return this;
  }

  setHeaders(value) {
    if (typeof value !== 'object') {
      throw new InvalidTypeError('Headers must be an object.');
    }

    this.headers = {...value};
    return this;
  }

  setData(value) {
    if (null !== value && typeof value !== 'object') {
      throw new InvalidTypeError('Data must be an object.');
    }

    if (null === value) {
      this.data = null;
    } else {
      this.data = cloneDeep(value);
    }

    return this;
  }

  build() {
    return {
      isAuthenticated: this.isAuthenticated,
      url: this.url,
      method: this.method,
      params: this.params,
      headers: this.headers,
      data: this.data,
    };
  }
}