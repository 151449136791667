<template>
  <div class="schedule">
    <PreloaderSpinner v-if="loading"/>
    <ScheduleTable
        :type="type"
        :studyDays="studyDays"
        :bells="bells"
        :schedule="schedule"
        :isMobile="isMobile"
        :canReserve="canReserve"
    />
    <template v-if="canReserve">
      <ReserveSuccessModal />
    </template>
  </div>
</template>

<script setup>
import {ref, defineProps, onMounted, onUnmounted} from "vue";
import {api} from "@/services/backend";
import {studyDays} from "@/data/days";
import {MOBILE_WIDTH} from "@/data/constants";
import ScheduleTable from "@/features/schedule/components/ScheduleTable.vue";
import PreloaderSpinner from "@/components/PreloaderSpinner.vue";
import ReserveSuccessModal from "@/components/ReserveSuccessModal.vue";

const bells = ref([]);
const schedule = ref([]);
const isMobile = ref(false);
const loading = ref(false);

const props = defineProps({
  type: {
    type: Number,
    required: true,
  },
  teacherName: String,
  schoolClassName: String,
  classroomName: String,
  canReserve: Boolean
});

const getBells = async () => {
  loading.value = true;
  try {
    const response = await api.bell.all();
    bells.value = response.bells;
    loading.value = false;
  } catch (error) {
    console.error('error', error);
  }
}

const getSchedule = async () => {
  loading.value = true;
  try {
    const response = await api.schedule.find(
        new URLSearchParams({
          group: props.schoolClassName ? props.schoolClassName : '',
          teacher: props.teacherName ? props.teacherName : '',
          classroom: props.classroomName ? props.classroomName : '',
        })
    );
    schedule.value = response.schedule;
    loading.value = false;
  } catch (error) {
    console.error('error', error);
  }
}

const onResize = () => {
  isMobile.value = window.innerWidth < MOBILE_WIDTH;
};

onMounted(() => {
  window.addEventListener("resize", onResize);
  onResize();
  getBells();
  getSchedule();
});

onUnmounted(() => {
  window.removeEventListener("resize", onResize);
});
</script>

<style lang="scss" scoped>
.schedule {
  overflow-x: auto;
}
</style>