import {getAuthHeaders} from "../token/auth";

const toAxios = (request) => {
  const config = {
    url: request.url,
    method: request.method,
    headers: {...request.headers},
  };

  if (null !== request.params) {
    config.params = request.params;
  }

  if (null !== request.data) {
    config.data = request.data;
  }

  if (request.isAuthenticated) {
    Object.assign(config.headers, getAuthHeaders());
  }

  return config;
};

export {toAxios};