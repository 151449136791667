import {DEFAULT_API_URL} from "../data/constants";

export function getBaseUrl() {
  // if (undefined !== process && process.env && process.env.VUE_APP_API_BASEURL) {
  //   console.log(process.env.VUE_APP_API_BASEURL);
  //   return process.env.VUE_APP_API_BASEURL;
  // }

  console.log(DEFAULT_API_URL);
  return DEFAULT_API_URL;
}